import React, { useEffect } from 'react';
import '../styles/AboutPage.css';
import Footer from '../components/footer';

const AboutPage = () => {
  useEffect(() => {
    document.body.classList.add('about-page');
    return () => {
      document.body.classList.remove('about-page');
    };
  }, []);

  return (
    <div>
    <div className="about-container">
      <h1 className='about-page-title'>About Swing TT</h1>

      {/* Media (images and video) */}
      <div className="media-container">
        <div className="media-left">
          <img src="https://swingtt.s3.us-east-2.amazonaws.com/Capture-5.jpg" alt="Slideshow Left" />
        </div>

        <div className="media-center">
          <video controls>
            <source src="https://swingtt.s3.us-east-2.amazonaws.com/Drone+Multiple+Courses+Golf+Ball+(1).mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="media-right">
          <img src="https://swingtt.s3.us-east-2.amazonaws.com/Capture-4.jpg" alt="Slideshow Right" />
        </div>
      </div>

      {/* Text Blocks */}
      <div className="text-block">
        <div className="text-left text-box">
          <h2>About Us</h2>
          <p>
            Play a round: choose from courses around the world, and play with friends.<br/>
            Dial in your swing: Practice on the range and use the analytics to see your club face,
            swing speed, distance, and much more.<br/>
            Book lessons: Experience top-notch golf lessons in our state-of-the-art simulator,
            perfect for all ages and skill levels, from beginner to expert!<br/>
            Fun for kids: Fun for all! Play one of our many interactive mini-games, or for non-
            golfers, enjoy our multi-sport package featuring foot golf, penalty kicks, American
            football, and more!
          </p>
        </div>

        <div className="text-right text-box">
          <h2>How it Works</h2>
          <p>Step 1: Book a simulator: go to our website and book a tee time for at least an hour.</p>
          <p>Step 2: Arrive 10 minutes early, order from our menu, have a drink and be ready to tee it high and let it fly.</p>
          <p>Step 3: Swing Sip Repeat. Sit with friends, play courses from around the world, or dial in your game and practice on one of our many driving ranges.</p>
          <p>Club Rental: Don’t have your own clubs? No problem, ask our friendly staff about renting a set on arrival.</p>
        </div>
      </div>
     
    </div>
    <Footer/>
    </div>
  );
};

export default AboutPage;
