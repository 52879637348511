import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LessonsPage.css';
import Footer from '../components/footer';

const lessons = [
  {
    title: 'Kids Golf Lessons',
    description: 'Learn the basics of golf in a fun and friendly environment. Our beginner lessons cover the fundamentals of the game. For ages 16 and under.',
  },
  {
    title: 'Adult Golf Lessons',
    description: 'Take your golf skills to the next level with our intermediate lessons. Perfect for those looking to improve their game.',
  },
];
const instructorProfile = {
  name: 'Benjamin Martin',
  bio: `
   
Benjamin Martin is a seasoned professional golfer recognized for his achievements on international circuits. He trained at The Pendleton School/Leadbetter Golf Academy in the U.S. and earned a BT National Diploma in Sport from Worthing College in the U.K. His amateur career saw him represent Trinidad & Tobago, where he set records and secured multiple victories, including consecutive wins at the Trinidad & Tobago Amateur Open Championship and an impressive 64-shot score in the Cayman Islands, breaking a regional record.

Turning pro in 2010, Benjamin competed in prestigious events such as the Clutch Pro Tour and the DP World Tour Qualifying School, achieving top placements in tournaments like the Trinidad & Tobago Open and Jamaican Open. Certified by the United States Golf Teachers’ Federation, he is dedicated to coaching and shaping future talent in Trinidad & Tobago, earning respect as both a competitor and mentor.
  `,
  image: 'https://swingtt.s3.us-east-2.amazonaws.com/Screenshot+2024-10-30+at+7.35.45%E2%80%AFPM.png'
};


const LessonsPage = () => {
  const navigate = useNavigate(); // Using useNavigate for redirection

  useEffect(() => {
    document.body.classList.add('lessons-page');
    return () => {
      document.body.classList.remove('lessons-page');
    };
  }, []);

  return (
    <div className="lessons-page">
      <header className="header">
        <video className="header-video" autoPlay muted loop playsInline>
          <source src="https://swingtt.s3.us-east-2.amazonaws.com/Teaching2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </header>

      <div className="container">
        <h1 className="title">Golf Lessons</h1> {/* Moved the title to the container below the video */}
        <div className="intro">
          <p>Welcome to our golf lessons page. We offer a variety of lessons to improve your game. Ask about lessons, call or message us to make a booking.</p>
        </div>
        <div className="lesson-section">
          {lessons.map((lesson, index) => (
            <div className="card" key={index}>
              <h2>{lesson.title}</h2>
              <p>{lesson.description}</p>
            </div>
          ))}
        </div>

        <div className="instructor-profile">
          <h2>Meet Your Instructor</h2>
          <div className="profile-container">
          <a href="https://swingtt.s3.us-east-2.amazonaws.com/Screenshot+2024-10-30+at+7.35.45%E2%80%AFPM.png" target="_blank" rel="noopener noreferrer">
              <img src={instructorProfile.image} alt={`${instructorProfile.name} Profile`} className="profile-picture" />
            </a>
            <div className="profile-details">
              <h3>{instructorProfile.name}</h3>
              <p>{instructorProfile.bio}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LessonsPage;
