import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AdminLoginPage.css';

const AdminLoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole');
    if (token) {
      onLogin(true); // User is logged in
      if (role === 'admin') {
        navigate('/admin/dashboard');
      } else if (role === 'owner') {
        navigate('/owner/dashboard');
      }
    }
  }, [navigate, onLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform login logic for both Admin and Owner
    if (
      username === process.env.REACT_APP_ADMIN_USERNAME &&
      password === process.env.REACT_APP_ADMIN_PASSWORD
    ) {
      const token = 'adminToken'; // Mock token for admin
      localStorage.setItem('authToken', token);
      localStorage.setItem('userRole', 'admin'); // Store the role
      onLogin(true); // Mark the user as logged in
      navigate('/admin/dashboard'); // Redirect to the admin dashboard
    } else if (
      username === process.env.REACT_APP_OWNER_USERNAME &&
      password === process.env.REACT_APP_OWNER_PASSWORD
    ) {
      const token = 'ownerToken'; // Mock token for owner
      localStorage.setItem('authToken', token);
      localStorage.setItem('userRole', 'owner'); // Store the role
      onLogin(true); // Mark the user as logged in
      navigate('/owner/dashboard'); // Redirect to the owner dashboard
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <div className="admin-login-container">
      <h1>Admin/Owner Login</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLoginPage;
