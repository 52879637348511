import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';
import ReservationsPage from './pages/ReservationsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LessonsPage from './pages/LessonsPage';
import MenuPage from './pages/MenuPage';
import UserInfoPage from './pages/UserInfoPage';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminDashboard from './pages/AdminDashboard';
import OwnerDashboard from './pages/OwnerDashboard'; 
import './App.css'; // Import the CSS file
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react"
import BookingPage from './pages/booking';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/reservations" element={<BookingPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/lessons" element={<LessonsPage />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/user-info" element={<UserInfoPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/admin" element={<AdminLoginPage onLogin={setIsLoggedIn} />} />
          <Route path="/admin/dashboard" element={isLoggedIn ? <AdminDashboard /> : <AdminLoginPage onLogin={setIsLoggedIn} />} />
          <Route path="/owner/dashboard" element={isLoggedIn ? <OwnerDashboard /> : <AdminLoginPage onLogin={setIsLoggedIn} />} /> 
        </Routes>
      </div>
      <SpeedInsights/>
      <Analytics />
    </Router>
  );
}

export default App;